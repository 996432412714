<template>
  <div class="sticky-bar">
    <div class="sticky-bar__container">
      <div
        v-for="(item, i) in navigationItems"
        :key="'item-' + i"
      >
        <NuxtLink
          :to="item.path"
          class="sticky-bar__item"
          :aria-label="item.title"
        >
          <component
            :is="item.icon.component"
            v-bind="item.icon.props || {}"
            class="sticky-bar__icon"
          />
          {{ item.title }}
        </NuxtLink>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import SvgHouseOutlined from '~/components/svg/HouseOutlined.vue'
import SvgCalendar from '~/components/svg/Calendar.vue'

const navigationItems = [
  {
    title: 'Nos biens',
    icon: {
      component: SvgHouseOutlined,
      props: {
        inheritStroke: true,
        stroke: 'white'
      }
    },
    path: '/acheter/annonces'
  },
  {
    title: 'Prendre RDV',
    icon: {
      component: SvgCalendar,
      props: {
        inheritStroke: true,
        stroke: 'white',
        strokeWidth: 1.5
      }
    },
    path: '/booking/charaf.idali/call'
  }
]

</script>

<style lang="scss" scoped>
.sticky-bar {
  display: flex;
  position: sticky;
  z-index: 1003;
  top: 70%;
  height: 0;
  justify-content: end;
  margin-right: 20px;

  &__container {
    display: flex;
    flex-direction: column;
    align-items: end;
  }

  &__item {
    width: fit-content;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    background-color: #4C00FF;
    padding: 1rem;
    margin-bottom: 1rem;
    border-radius: 0.5rem;
    color: white;
    text-decoration: none;
    font-weight: 600;
  }

  &__icon {
    width: 1.5rem;
    height: 1.5rem;
  }
}

@media (width <= 600px) {
  .sticky-bar {
    display: none;
  }
}

</style>
